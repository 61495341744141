export default {
    data () {
        return {
          title:
            'Платформа Курсы от\u00A0МТС\u00A0Линк подходит для\u00A0обучения',
          cards: [
            {
              title: 'Сотрудников',
              content: `
                        <ul>
                            <li>Автоматизируйте найм и&nbsp;онбординг</li>
                            <li>Поддерживайте корпоративную культуру</li>
                            <li>Вовлекайте удаленных сотрудников</li>
                            <li>Рассказывайте об изменениях в&nbsp;компании</li>
                        </ul>
                    `,
              link: '/task/obuchenie_sotrudnikov/',
            },
            {
              image: {
                src: require('~/assets/img/products-westudy/platform_1.jpg'),
                srcWebp: require('~/assets/img/products-westudy/platform_1.webp'),
                srcTablet: require('~/assets/img/products-westudy/platform_1_tablet.jpg'),
                srcTabletWebp: require('~/assets/img/products-westudy/platform_1_tablet.webp'),
                srcMobile: require('~/assets/img/products-westudy/platform_1_mobile.jpg'),
                srcMobileWebp: require('~/assets/img/products-westudy/platform_1_mobile.webp'),
                alt: 'Мужчина с ноутбуком',
              },
            },
            {
              image: {
                src: require('~/assets/img/products-westudy/platform_2.jpg'),
                srcWebp: require('~/assets/img/products-westudy/platform_2.webp'),
                srcTablet: require('~/assets/img/products-westudy/platform_2_tablet.jpg'),
                srcTabletWebp: require('~/assets/img/products-westudy/platform_2_tablet.webp'),
                srcMobile: require('~/assets/img/products-westudy/platform_2_mobile.jpg'),
                srcMobileWebp: require('~/assets/img/products-westudy/platform_2_mobile.webp'),
                alt: 'Женщина и мужчина',
              },
            },
            {
              title: 'Клиентов и\u00A0партнеров',
              content: `
                        <ul>
                            <li>Рассказывайте о&nbsp;вашем бренде, продукте или&nbsp;услуге</li>
                            <li>Сокращайте время на&nbsp;организацию обучения без&nbsp;потери эффективности</li>
                            <li>Увеличивайте партнерские продажи и&nbsp;зарабатывайте больше</li>
                        </ul>
                    `,
              link: '/task/obuchenie_partnerov/',
            },
            {
              title: 'Студентов',
              content: `
                        <ul>
                            <li>Проводите лекции и&nbsp;вебинары</li>
                            <li>Привлекайте больше студентов из&nbsp;регионов и&nbsp;увеличивайте охват обучения</li>
                            <li>Принимайте экзамены</li>
                            <li>Администрируйте процесс обучения без&nbsp;усилий</li>
                        </ul>
                    `,
              link: '/task/obuchenie_studentov/',
            },
            {
              image: {
                src: require('~/assets/img/products-westudy/platform_3.jpg'),
                srcWebp: require('~/assets/img/products-westudy/platform_3.webp'),
                srcTablet: require('~/assets/img/products-westudy/platform_3_tablet.jpg'),
                srcTabletWebp: require('~/assets/img/products-westudy/platform_3_tablet.webp'),
                srcMobile: require('~/assets/img/products-westudy/platform_3_mobile.jpg'),
                srcMobileWebp: require('~/assets/img/products-westudy/platform_3_mobile.webp'),
                alt: 'Женщина с ноутбуком',
              },
            },
          ],
        }
    }
}
